body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff !important;
}

.ant-layout-sider-children {  
  background: #f5f5f5c7;  
}

.ant-menu-item {
  background-color:white;
  -webkit-box-shadow:0 3px 0 0 grey; 
  box-shadow:0 3px 0 0 #eee;
}

.ant-layout-header { 
  background:#fff !important;
}