.setting {
    cursor: pointer;
    float: right;
    //margin-top: -24px;
    margin-right: 15px;
    // height: 85px;
    .ant-card-meta-avatar {
      float: left;
      padding-right: 5px;
    }
    .ant-card-body {
      padding: 24px;
      padding-top: 14px;
      padding-bottom: 0px;
      /* margin-bottom: 1px; */
    }
    .ant-btn-group {
      .ant-btn {
        height: 45px;
        //padding: 4px 4px;
        .ant-card {
          width: 100%;
          display: contents;
          .ant-card-body {
            padding: 0px;
            .ant-card-meta {
              margin: 0;
              .ant-card-meta-avatar {
                .ant-image-img {
                  display: block;
                  width: 100%;
                  height: 35px;
                  border-radius: 5px;
                }
              }
              .ant-card-meta-detail {
                overflow: hidden;
                padding-top: 0px;
                > div:not(:last-child) {
                  margin-bottom: 0px;
                }
                .ant-card-meta-title {
                  overflow: hidden;
                  color: var(--amplify-text-dark);
                  font-weight: 500;
                  font-size: 13px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  text-align: left;
                  margin: 0 !important;
                  //margin-left: -10px;
                }
                .ant-card-meta-description {
                  color: rgba(0, 0, 0, 0.45);
                  font-size: 10px;
                  margin-top: -2px;
                  margin: 0 !important;
                  padding-top: 0 !important;
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  
    .ant-badge {
      top: 25px;
      .anticon {
        font-size: 22px !important;
      }
    }
  
    .ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: none;
    }
    .ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: none;
    }
 
  }

  .logo-header{
    padding-left: 10px;
  }
  .ant-card-meta-description {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 10px;
  }
  .ant-card-meta-title {
    margin: 0 !important;
    font-family: 'DM Sans Bold';
  }
  .ant-card-bordered{
    border: none !important;
  }