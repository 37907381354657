.welcomeNode__wrapper {
  width: 240px;
  background-color: white;
  box-shadow: 0px 5px 20px #452ece0f;
  box-shadow: 0px 5px 20px #452ece0f;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  .welcomeNode__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    border-bottom: 1px solid #eee;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 40px;
    .welcomeNode__left {
      flex: 0.8;
      display: flex;
      flex-direction: row;
      align-items: center;
      .welcomeNode__header_icon {
        width: 20px;
        height: 20px;
        margin-right: 15px;
      }
      p {
        margin: 0;
      }
    }
    .welcomeNode__right {
      flex: 0.2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .welcomeNode__dots {
        width: 15px;
        height: 15px;
      }
    }
  }
  .welcomeNode__body {
    padding: 10px 20px;
    .welcomeNode__section {
      padding-bottom: 20px;
      text-align: left;
      .form-group{
          margin-block-end: 10px;
      }
      button{
          color: rgb(31, 170, 112);
          float: right;
      }
      p {
        font-size: 12px;
      }
      label {
        font-size: 12px;
        margin-right: 10px;
        span{
            font-size: 13px;
            display: inline !important;
            color: red;
            margin-left: 2px;
        }
      }     
      
      select , section {
        background-color: #e16666 !important;
        max-width: 200px;
        background: #573bff0d 0% 0% no-repeat padding-box !important;
        border-radius: 5px !important;
        height: 34px !important;
        border: none !important;
        color: #000 !important;
        font-size: 12px !important;
        option {
          max-width: 200px !important;
          background: #573bff0d 0% 0% no-repeat padding-box !important;
          border-radius: 5px !important;
          height: 34px !important;
          border: none !important;
          color: #000 !important;
          font-size: 12px !important;
        }
        #dropdown-item-button {
          max-width: 200px;
          background: #573bff0d 0% 0% no-repeat padding-box;
          border-radius: 5px;
          height: 34px;
          border: none;
          color: #000;
          font-size: 12px;
        }
        ::after {
          float: right;
          margin: 8px auto;
          text-align: center;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .dropdown {
        #dropdown-item-button {
          width: 100%;
          background: #573bff0d 0% 0% no-repeat padding-box;
          border-radius: 5px;
          height: 34px;
          border: none;
          color: #000;
          font-size: 12px;
        }
        ::after {
          float: right;
          margin: 8px auto;
          text-align: center;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .welcomeNode__addInputs {
    border-top: 1px solid #eee;
    padding: 10px 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    .welcomeNode__section {
      display: flex;
    }
    .welcomeNode__footer {
      display: flex;
      padding-bottom: 15px;
      .welcomeNode__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        p {
          font-weight: bold;
          font-size: 12px;
          line-height: 1;
          margin: 0;
          padding-left: 10px;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}


.touchdevice-flow {
  .react-flow__handle {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    background-color: #9f7aea;

    &.input {
      border-radius: 50% !important;
      background-color: #5cecd2 !important;
      border: 1px solid #fd4aa5 !important;
      width: 10px !important;
      height: 10px !important;
    }

    &.output {
      border-radius: 50% !important;
      background: #edc2f5 !important;
      border: 1px solid #fd4aa5 !important;
      width: 10px !important;
      height: 10px !important;
    }
  }

  .react-flow__handle.connecting {
    animation: bounce 1600ms infinite ease-out;
  }
}



@keyframes bounce {
  0% {
    transform: translate(0, -50%) scale(1);
  }
  50% {
    transform: translate(0, -50%) scale(1.1);
  }
}