@font-face {
  font-family: 'DM Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Regular'), url('../fonts/DMSans-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Italic'), url('../fonts/DMSans-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Medium'), url('../fonts/DMSans-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Medium Italic'), url('../fonts/DMSans-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Bold'), url('../fonts/DMSans-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Bold Italic'), url('../fonts/DMSans-BoldItalic.woff') format('woff');
  }
  body{
    color: #030014 !important;
    font-family: 'DM Sans Regular'!important;
    font-size: 14px!important;
  }

.react-flow__handle.react-flow__handle-top.nodrag.target.connectable {
  border-radius: 50% !important;
  background-color: #bff1bf !important;
  border: 1px solid #ef8e55 !important;
}

.react-flow__handle.react-flow__handle-bottom.nodrag.source.connectable {
  border-radius: 50% !important;
  background: #efd558 !important;
  border: 1px solid #f96d6d !important;
}

.save__controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
  font-size: 12px;
}
.save__controls .ant-tag {
  position: absolute;
  font-size: large;
  right: 78vw;
  font-weight: bolder;
  cursor: not-allowed !important;
}

.save__controls button {
  margin-left: 5px;
}

.react-flow__node a {
  font-weight: 700;
  color: #111;
}

.react-flow__node.dark-node {
  background: #0041d0;
  color: #f8f8f8;
}

.react-flow__node.dark {
  background: #557;
  color: #f8f8f8;
}

.react-flow__node-selectorNode {
  font-size: 12px;
  background: #f0f2f3;
  border: 1px solid 555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-selectorNode .react-flow__handle {
  border-color: #f0f2f3;
}

@media screen and (min-width: 768px) {
  .overview-example__add {
    display: block;
  }
}
.react-flow__node .react-flow__node-configurationNode {
  background: rgba(239, 245, 244, 0.51);
  color: #030014;
  border: none;
  border-radius: 5px;
  cursor: move;
  /* max-width: 135px;
  max-height: 70px; */
}
.react-flow__node .react-flow__node-configurationNode .config-node-wrap {
  text-align: center;
  /* .config-node-label {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  } */
}
.react-flow__node .react-flow__node-configurationNode .config-node-wrap .input {
  border-radius: 50% !important;
  background-color: #5cecd2 !important;
  border: 1px solid #fd4aa5 !important;
  width: 10px !important;
  height: 10px !important;
}
.react-flow__node .react-flow__node-configurationNode .config-node-wrap .output {
  border-radius: 50% !important;
  background: #edc2f5 !important;
  border: 1px solid #fd4aa5 !important;
  width: 10px !important;
  height: 10px !important;
}
.react-flow__node .react-flow__node-configurationNode .config-node-wrap .config-icon {
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 15px;
  color: #7c8481;
}
.react-flow__node .react-flow__node-configurationNode .config-node-wrap .detail-wrap svg {
  color: #377cf7;
  font-size: 20px;
}
.react-flow__node .react-flow__node-configurationNode .config-node-wrap .detail-wrap span {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.react-flow__node.react-flow__node-start {
  background: rgba(106, 228, 144, 0.56);
  color: #78e6b3;
  border: #24a584;
  border-style: solid;
  border-width: 5px;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  text-align: center;
  /* padding: 10px; */
}
.react-flow__node.react-flow__node-start .output {
  border-radius: 50% !important;
  background: #ff1561 !important;
  border: 4px solid #24a584 !important;
  top: 50%;
  width: 15px;
  height: 15px;
  right: -10px;
}
.react-flow__node.react-flow__node-start .svg {
  display: table-row-group;
  font-size: 45px;
  overflow: visible;
  top: 5px;
  position: absolute;
  left: 5px;
  color: #ff1561;
}

.react-flow__node.react-flow__node-end {
  background: rgba(253, 165, 187, 0.81);
  color: #ff295f;
  border: #ff295f;
  border-style: solid;
  border-width: 5px;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  text-align: center;
  /* padding: 10px; */
}
.react-flow__node.react-flow__node-end .input {
  border-radius: 50% !important;
  background: #24a584 !important;
  border: 4px solid #ff1561 !important;
  top: 50%;
  width: 15px;
  height: 15px;
  left: -10px;
}
.react-flow__node.react-flow__node-end .svg {
  display: table-row-group;
  font-size: 45px;
  overflow: visible;
  top: 5px;
  position: absolute;
  left: 5px;
  color: #1890ff;
}

.node-property {
  width: 10vw;
  right: 5vw;
  position: absolute;
  z-index: 10;
  bottom: 5px;
  background-color: #b1b1b16b;
  border-radius: 5px;
  padding: 5px;
  display: grid;
}

.node-property .form {
  display: grid;
}

.node-property .form .button {
  display: block;
  /* width: 100%;
  height: 20px; */
  color: #89afc5;
  border-color: #87c5e8;
  border-width: 1px;
  /* font-size: 12px; */
  margin-block-start: 5px;
  /* float: right; */
}

.node-property .form .input {
  margin-block-end: 1px;
}

.ant-tag > .anticon + span, .ant-tag > span + .anticon {
  margin-left: 7px;
  display: inline-grid;
  width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.left_side_bar .sidebar {
  display: flex;
  flex: 0.15;
  height: 100vh;
  background-color: #faf9ff;
  flex-direction: column;
  padding-left: 20px;
  justify-content: center;
}
.left_side_bar .sidebar .wrapper {
  height: 40px;
  width: 190px;
  background-color: white;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-box-shadow: 0 3px 0 0 grey;
  box-shadow: 0 3px 0 0 #eee;
  border: none;
}
.left_side_bar .sidebar .wrapper p {
  color: white;
}
.left_side_bar .sidebar .wrapper .button_icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.flow-list.ant-list {
  /* box-sizing: border-box;
  margin: 0;
  padding: 25px 50px 75px 100px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative; */
  /* width: 80vw; */
  padding: 50px 100px 20px 100px;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  flex-direction: row;
  -webkit-box-shadow: 0 3px 0 0 grey;
  box-shadow: 0 3px 0 0 #eee;
  border: 1px;
}

.flow-list-spin {
  position: static !important;
  display: inline-block !important;
  width: 90% !important;
  top: 55% !important;
  z-index: 10 !important;
  position: fixed !important;
}

// Added new by Asitha
.ant-btn-primary {
  color: #fff;
  background-color: #573BFF!important;
  border-color: #573BFF!important;
  font-size: 14px!important;
  padding: 12px 20px!important;
  font-family: "DM Sans Bold"!important;
  height: inherit!important;
  border-radius: 5px !important;

  &:hover {
      background-color: #4528f3!important;
      border-color: #4528f3!important;
  }
  &.disabled {
      background-color: #9280ff;
      border-color: #9280ff;
  }
  &:disabled {
      background-color: #9280ff;
      border-color: #9280ff;
  }
}

.ant-btn-primary.ant-btn-dangerous {
  color: #fff;
  background-color: #ff4d4f!important;
  border-color: #ff4d4f!important;
  font-size: 14px!important;
  padding: 12px 20px!important;
  font-family: "DM Sans Bold"!important;
  height: inherit!important;
  border-radius: 5px !important;

  &:hover {
      background-color: #cd3133 !important;
      border-color: #cd3133 !important;
  }

}

.welcomeNode__section button[type='submit']{  
  background-color: #573BFF !important;
  border-color: #573BFF !important;
  font-size: 11px !important;
  padding: 8px 20px !important;
  font-family: "DM Sans Bold" !important;
  height: inherit !important;
  border-radius: 5px !important;
  color: #fff !important;
  
    &:hover {
        background-color: #4528f3!important;
        border-color: #4528f3!important;
        cursor: pointer !important;
    }
    &.disabled {
        background-color: #9280ff;
        border-color: #9280ff;
    }
    &:disabled {
        background-color: #9280ff;
        border-color: #9280ff;
    }

}
.ant-layout-sider-children,
.ant-layout,
.ant-layout-content{
  background: #FAF9FF !important;
}
.react-flow.touchdevice-flow{
  border-radius: 5px;
}
.ant-menu-item {
  box-shadow: none;
  border-radius: 5px;
}
.ant-menu-title-content{
  font-family: 'DM Sans Bold';
  font-size: 14px;
  color:#030014 ;
}
.ant-avatar-sm{
  width: 18px !important;
  height: 18px !important;
  border-radius: 0 !important;
}
.ant-menu{
  background: none !important;
    margin: 10px  !important;
    border: none !important;
}
.ant-menu-inline{
  width: auto !important;
}
.welcomeNode__section label{
  font-family: 'DM Sans Regular'!important;
  color: rgba(3, 0, 20, 0.6) !important;
}
.welcomeNode__wrapper .welcomeNode__body .welcomeNode__section select{
  width: 100% !important;
  float: none !important;
  font-family: 'DM Sans Medium';
  font-size: 14px !important;
  padding: 8px;
}
.field-string input{
  width: 100% !important;
  background: #573bff0d 0% 0% no-repeat padding-box !important;
  border: none!important;
  border-radius: 5px!important;
  font-size: 14px;
  padding: 8px;
  font-family: 'DM Sans Medium';
}
.ant-modal-confirm-btns .ant-btn-primary{
  width: 100% !important;
}
.welcomeNode__header_title{
  font-family: 'DM Sans Bold';
  border: none !important;
  color: rgba(3, 0, 20, 1) !important;
}
.version-dropdown{
  padding: 0 10px;
}
.version-dropdown .ant-space-item{
  color: rgba(3, 0, 20, 0.6);
}
h3.version{
  display: block;
  padding: 10px 10px 0px 10px;
  font-size: 18px !important;
  font-family: 'DM Sans Bold';
  margin: 0 !important;
}
/*# sourceMappingURL=nodes.css.map */
